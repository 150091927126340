/* Welcome to Compass. Use this file to define print styles.
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/print.css" media="print" rel="stylesheet" type="text/css" /> */

.noscroll {
  .outer-wrapper {
    display: none;
  }

  .overlay-wrapper {
    .close-button {
      display: none;
    }

    .recipe {
      .copy {
        span {
          display: block;
        }
      }

      .recipe-action {
        display: none;
      }
    }
  }
}

.recipe-wrapper {
  .recipe-action {
    display: none;
  }

  .copy span {
    display: block;
  }

  iframe {
    display: none;
  }

  img.do-print {
    display: block;
  }

  .section.related {
    display: none;
  }

  .recipe-title {
    display: none;
  }

  .section {
    width: 60%;
  }

  .ingredients {
    .other-tags {
      display: none;
    }
  }
}

body.recipe-video {
  .recipe-wrapper {
    margin-left: 0 !important;
  }
}

// Print table only on pairings
.pairing-detail {
  .pairing-masthead,
  .pairing-description,
  .pairing-carousel,
  .pairing-table .cta,
  .tips-and-tools,
  .partnership-posts-grid,
  .more-pairings,
  .quiz-tout,
  .copyright.mobile-no {
    display: none !important;
  }

  .pairing-table td {
    border: 1px solid black;
  }

  .pairing-table table tr {
    &:nth-child(n + 7) {
      display: table-row !important;
    }
  }

  .copyright {
    margin-top: 2em;
  }
}
